import { connect } from 'react-redux';
import {CustomerDetailsForm} from './index';
import { IRootState } from 'app/reducers';
import { AnyAction } from 'redux';
import { IDispatchFromProps, IStateFromProps } from './types';
import { ThunkDispatch } from 'redux-thunk';
import { SetupActionsNS } from 'app/actions/setup/setupActions';
import { BookingActionsNS } from 'app/actions/booking/bookingActions';
import { ICustomer } from 'app/services/booking/booking.types';
import {IBookingTag} from "shared-types/index";
import PhoneNumberService from "shared-services/phone-number-service/index";

const NS = 'CustomerDetailsFormContainer';

const mapStateToProps = ({widget}: IRootState): IStateFromProps => {

  const { schedule, theme, booking, wrapperStyle, activeVenue, triedNext, standbyData, country, phoneWithoutPrefix, isDetectedPreviousSB = false, forcedValidate } = widget;

  const tags: IBookingTag[] = schedule && schedule.tags ? schedule.tags.filter(t => t.online) : [];

  const customerDetails = (standbyData || booking).customer;
  const { isBookedBy, bookedBy } = booking;
  // const tempCountry = customerDetails.country || country || activeVenue?.country || null;

  // Get country code from customerDetail's phone when it exists.
  let customerCountry: string;
  if(customerDetails && customerDetails.phone) {
      const parseNumber: any = PhoneNumberService.parseNumber(customerDetails.phone);
      if(parseNumber && typeof parseNumber !== 'string') {
        if(parseNumber.nationalNumber)
        customerCountry = parseNumber.country;
      }
  }

  const tempCountry = customerCountry || country || activeVenue?.country || null;

  if (tags && booking && booking.savedTags) {
    // marks as selected if exists in booking.tags
    tags.forEach(t => t.selected = booking.savedTags.some(o => o === t._id));
  }

  return {
    wrapperStyle,
    theme,
    tags,
    phoneWithoutPrefix: phoneWithoutPrefix || PhoneNumberService.getInternationPhoneNumberWithoutPrefix(customerDetails?.phone, tempCountry),
    country: tempCountry, //activeVenue ? activeVenue.country : null,
    customerDetails: customerDetails,
    triedNext,
    policyAgreementText: activeVenue.widgetSettings.publicTermsAndConditionsMessage,
    isTC: true,
    standbyData,
    isDetectedPreviousSB,
    titleSubscription: activeVenue.widgetSettings.titleSubscription,
    defaultEmailSubscribed: activeVenue.widgetSettings.defaultEmailSubscribedForWidget,
    venueName: activeVenue.name,
    venueCountry: activeVenue.country,
    privacyPolicyURL: activeVenue.widgetSettings.privacyPolicyURL,
    titleTermsAndCondition: activeVenue.widgetSettings.titleTermsAndCondition,
    isCustomerInformationUpdated: widget.isCustomerInformationUpdated,
    venueURL: activeVenue.url,
    bookedBy,
    isBookedBy,
    forcedValidate,
    enableBookedByOnWidget: (activeVenue.widgetSettings as any).enableBookedByOnWidget,
  };
};


/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IDispatchFromProps => {
  return {
    handleUpdate: (
      customerDetails: ICustomer,
      tags: IBookingTag[],
      isValid: boolean,
      country: string,
      phoneWithoutPrefix: string,
      isBookedBy: boolean,
    ) => {
      dispatch(BookingActionsNS.changedCustomerDetails(customerDetails, tags, isValid, country, phoneWithoutPrefix));
      dispatch(BookingActionsNS.setBookedBy(isBookedBy))
    },

    handleUpdateBookedBy: (
      customerDetails: ICustomer,
      country: string,
      phoneWithoutPrefix: string,
    ) => {
      dispatch(BookingActionsNS.changedBookedBy(customerDetails));
    },

    loadLibPhoneNumber: () => {
      dispatch(SetupActionsNS.loadLibPhoneNumber());
    }
  }
};

const CustomerDetailsFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerDetailsForm as any);

export default CustomerDetailsFormContainer;
