/* eslint-disable react/prop-types */
import React, { Component, createRef, Fragment, useEffect, useMemo, useState } from 'react';
import style from './style.module.scss';
import { withStyles } from '@material-ui/styles';
import MuiTextField from '@material-ui/core/TextField';
import {Paper, FormControlLabel, Typography, Divider, FormControl, Tooltip} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import {wrapperStyleType} from 'app/models';
import {IStateFromProps, IDispatchFromProps} from './types';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {withFormik, FormikProps} from 'formik';
import Box from '@material-ui/core/Box';
import * as Yup from 'yup';
// import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from 'app/reducers';
import {renderIf, UtilsService} from 'app/services/utils/utils.service';
import PhoneNumberService from "shared-services/phone-number-service/index";
import {phoneNumberTypes} from "shared-services/phone-number-service/phoneNumber.types";
import isEqual from 'react-fast-compare';
import FooterNavContainer from '../FooterNav/container';
import {ROUTE_NAMES} from 'app/services/route/route.types';
import ColumnWrap2Container from '../ColumnWrap2/container';
import AlertPanel from '../AlertPanel';
import {LocationService} from 'app/services/location/location.service';
import {IframeResizerService} from "app/services/iframeResizer/iframeResizer.service";
import FlagPhonePickerReact from "shared-components/flag-phone-picker-react";
import { FlagPhonePickerStyle } from 'shared-components/flag-phone-picker-react/types'
import CustomizeDialog from 'app/components/CustomizeDialog/index';
import StandbyConfirmPanelContainer from 'app/components/StandbyConfirmPanel/container';
import { CUSTOMER_AGREEMENT_MSG_WITH_BOOKED_BY, MessageService } from 'app/services/message/message.service';
import { trim, isEmpty } from "lodash"
import { ICustomizeDialogRef } from 'app/components/CustomizeDialog/types';
import { themeTypes } from 'shared-types/index';
import { BookingActionsNS } from 'app/actions/booking/bookingActions';
const ReactMarkdown = require('react-markdown/with-html');

const NS = 'CustomerDetailsForm';

const TextField = withStyles((props) => {
  return {
    root: {
      '& > .MuiInputLabel-shrink': {
        transform: 'translate(0, 5px) scale(0.75)'
      },
      '& .MuiInputBase-input': {
        padding: '5px 0 6px',
        marginTop: '4px'
      },
      '& .MuiFormHelperText-root': {
        fontSize: '1.2rem'
      },
      '& label': {
        zIndex: 10
      },
      '& label + .MuiInput-formControl': {
        marginTop: '12px'
      },
      "& .MuiInput-underline:before": {
        borderBottomColor: props.palette?.text?.primary
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: props.palette?.text?.primary
      },
      "& .MuiInput-underline.Mui-error:after": {
        borderBottomColor: "#f44336"
      },
      '& > .MuiFormLabel-root.Mui-focused': {
        color: props.palette?.text?.primary
      },
      '& > .MuiDivider-root': {
        color: props.palette?.text?.primary
      },
    },
  }
})(MuiTextField);

const TagLabel = withStyles({
  root: {
    '& .MuiCheckbox-root': {
      padding: '5px'
    }
  }
})(FormControlLabel);
interface IFormData {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  company?: string;
  notes?: string;
  country?: string;
  termsAgreementChecked: boolean; // externally this is corresponds to 'subscribed' in ICustomer
  tags?: boolean[];
  subscribed?: boolean;
  isBookedBy?: boolean;
  bookedByPhone?: string;
  bookedByFirstName?: string;
  bookedByLastName?: string;
  bookedByEmail?: string
  bookedByCompany?: string
  bookedSubscribed?: boolean;
}

interface IFormTags {
  [key: string]: boolean;
}

interface IState {
  isBookedBy: boolean
}


// workaround for initial checked state on load (https://github.com/mui-org/material-ui/issues/16434)
const Checkbox = UtilsService.getMuiFormikCheckBox;
const customerInputs = ['firstName', 'lastName', 'phone', 'email', 'company']
const bookedByInputs = ['bookedByFirstName', 'bookedByLastName', 'bookedByPhone', 'bookedByEmail', 'bookedByCompany']
class InnerForm extends Component<FormikProps<IFormData & IFormTags> & IStateFromProps & IDispatchFromProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {isBookedBy: false};
  }

  customizeDialogRef = createRef<ICustomizeDialogRef>()

  componentDidUpdate(prevProps: FormikProps<IFormData & IFormTags> & IStateFromProps & IDispatchFromProps) {
    if (!isEqual(prevProps.values, this.props.values) || !isEqual(prevProps.errors, this.props.errors)) {
      this.handleWholeFormChange();
    }

    if (this.props.forcedValidate > (prevProps.forcedValidate || 0) ) {
      [...customerInputs, ...bookedByInputs].forEach(input => {
        this.props.setFieldTouched(input)
      })
    }
  }

  componentDidMount() {
    this.handleWholeFormChange();
  }

  /**
   * Handler for form as a whole
   */
  handleWholeFormChange = async () => {
    const {firstName, lastName, phone, email, company, notes, country, subscribed, termsAgreementChecked, bookedByFirstName, bookedByLastName, bookedByPhone, bookedByEmail, bookedSubscribed, bookedByCompany, isBookedBy} = this.props.values;
    const {isValid, handleUpdate, handleUpdateBookedBy, standbyData} = this.props;
    // const phoneNational: string = phone ? PhoneNumberService.formatNumber(phone, this.state.country, true, phoneNumberTypes.NATIONAL) : null;
    const phoneNational: string = phone ? PhoneNumberService.formatNumber(phone, country, true, phoneNumberTypes.NATIONAL) : null;

    const tags = this.props.tags.filter(t => (this.props.values as any)[t._id]);
    const phoneWithPrefix = PhoneNumberService.formatInterNationalPhoneNumber(phone, country);
    const phoneWithoutPrefix = phone;
    handleUpdate({
      firstName, lastName,
      phone: phoneWithPrefix,
      phoneNational, email, company, notes,
      subscribed,
      termsAgreementChecked
    }, tags, termsAgreementChecked && isValid, country, phoneWithoutPrefix, isBookedBy);

    if (isBookedBy && !standbyData) {
      const bookedByPhoneWithPrefix = PhoneNumberService.formatInterNationalPhoneNumber(bookedByPhone, country);
      handleUpdateBookedBy({
        firstName: bookedByFirstName,
        lastName: bookedByLastName,
        phone: bookedByPhoneWithPrefix,
        company: bookedByCompany,
        email: bookedByEmail,
        subscribed: false, // we'll do update about this value once we have check-box for BB in Admin
      } as any, country, phoneWithoutPrefix)
    }

    this.props.validateForm()
  }

  render() {
    return <Form {...this.props} />
  }
}

const Form = (props: FormikProps<IFormData & IFormTags> & IStateFromProps & IDispatchFromProps) => {
  const {
    // custom props
    wrapperStyle,
    tags,
    country,
    loadLibPhoneNumber,
    theme,

    // formik props
    values,
    handleSubmit,
    touched,
    errors,
    handleChange,
    handleBlur,
    getFieldProps, // convenience props for values, handleChange, handleBlur
    setFieldValue,
    policyAgreementText,
    isDetectedPreviousSB,
    standbyData,
    titleSubscription,
    defaultEmailSubscribed,
    venueName,
    triedNext,
    validateForm,
    enableBookedByOnWidget,
  } = props;
  const isLandscape = !IframeResizerService.isStacked(wrapperStyle);
  const isStandard = wrapperStyle === wrapperStyleType.standard;
  const hasTags = tags && tags.length;
  const forceValidation: boolean = triedNext === ROUTE_NAMES.CUSTOMER_DETAILS;

  const { isBookedBy } = values
  loadLibPhoneNumber();

  const customizeDialogRef = createRef<ICustomizeDialogRef>()
  const renderLabelTermAndCondition = () => {
    const {
      country,
      policyAgreementText,
      venueName,
      titleTermsAndCondition = "",
      venueURL,
      privacyPolicyURL,
      theme
    } = props;
    let policyURL = isEmpty(privacyPolicyURL)
      ? venueURL
      : privacyPolicyURL;
    const isDark = theme ? theme.type === themeTypes.dark || theme.type === themeTypes.outlinedDark : false;

    if(policyURL && !/(http(s?)):\/\//i.test(policyURL)) {
      policyURL = `https://${policyURL}`
    }

    const EU_COUNTRIES = [
      'AT','BE','BG','HR','CY','CZ','DK','EE','FI','FR',
      'DE','GR','HU','IE','IT','LV','LT','LU','MT','NL',
      'PL','PT','RO','SK','SI','ES','SE','AL','AD','AM',
      'BY','BA','FO','GE','GI','IS','IM','XK','LI','MK',
      'MD','MC','ME','NO','RU','SM','RS','CH','TR','UA',
      'VA','GB','IE'
    ]

    if (!EU_COUNTRIES.includes(country)) {
      return (
        <div className={style.dynamicLabelContainer}>
          <div className={style.tcLink}>
            <CustomizeDialog
              ref={customizeDialogRef}
              customName={
                <span className={style.termsAndContidionsContentWrapper}>
                  I agree to the booking
                  <span className={style.termsAndContitionsTitle} onClick={() => customizeDialogRef.current?.handleClickOpen()}>
                    Terms and Conditions
                  </span>
                </span>
              }
              message={policyAgreementText}
              isTC={true}
              privacyPolicyURL={policyURL}
            />
          </div>
        </div>
      )
    }

    const firstArgreeText = trim(titleTermsAndCondition.split("{{termsAndConditions}}")[0] || '')
    let remainingText = titleTermsAndCondition.split("{{termsAndConditions}}")[1] || ''

    if (isEmpty(policyURL)) {
      remainingText = remainingText.replace('[Privacy Policy]({{{venuePrivacyUrl}}})', 'Privacy Policy')
    }

    return (
      <div className={style.dynamicLabelContainer}>
        <div className={style.tcLink}>
          <CustomizeDialog
            ref={customizeDialogRef}
            customName={
              <span className={style.termsAndContidionsContentWrapper}>
                {firstArgreeText}
                <span className={style.termsAndContitionsTitle} onClick={() => customizeDialogRef.current?.handleClickOpen()}>
                  Terms and Conditions
                </span>
                <span className={classNames({
                  [style.termsAndContitionsContent]: !isDark,
                  [style.termsAndContitionsContentDarkMode]: isDark
                })}>
                  <ReactMarkdown
                    linkTarget="_blank"
                    source={MessageService.getMessage(
                      remainingText,
                      { venueName, venuePrivacyUrl: policyURL },
                      null
                    )}
                    escapeHtml={false}
                  />
                </span>
              </span>
            }
            message={policyAgreementText}
            isTC={true}
            privacyPolicyURL={policyURL}
          />
        </div>
      </div>
    )
  }

  /**
   * iPhone's zoom into input text fields if they are less than 16px, so we device sniff here
   * and give Apple what it wants.
   */
  const useEnlargedFont: boolean = LocationService.detectPhone().isIPhone;

  const bookedByMode = isBookedBy && !standbyData && enableBookedByOnWidget

  const BookedByCustomer = useMemo(() => (
    <div style={{ marginTop: 40 }}>
      <Box display="flex" gridGap={8} alignItems="center">
        <Typography variant="subtitle1" >
          Your Details
        </Typography>
        <Tooltip placement="top-start" title={'Please enter details of the person making this booking. They will receive all email and SMS communication as well.'}>
          <InfoIcon fontSize='small'/>
        </Tooltip>
      </Box>

      <TextField
        className={classNames({
          [style.formText]: true,
          [style.formTextUseEnlargedFont]: useEnlargedFont
        })}
        id="bookedByFirstName" name="bookedByFirstName" label="First Name *"
        fullWidth
        {...getFieldProps('bookedByFirstName')}
        error={touched.bookedByFirstName && Boolean(errors.bookedByFirstName)}
        helperText={touched.bookedByFirstName ? errors.bookedByFirstName : ''}
      />
      <TextField
        className={classNames({
          [style.formText]: true,
          [style.formTextUseEnlargedFont]: useEnlargedFont
        })}
        id="bookedByLastName" name="bookedByLastName" label="Last Name *"
        fullWidth
        {...getFieldProps('bookedByLastName')}
        error={touched.bookedByLastName && Boolean(errors.bookedByLastName)}
        helperText={touched.bookedByLastName ? errors.bookedByLastName : ''}
      />
      <FlagPhonePickerReact
        name="bookedByPhone"
        searchCustomer={() => {
          return;
        }}
        customers={[]}
        value={values.bookedByPhone}
        handleBlur={handleBlur}
        handleChange={handleChange}
        country={country}
        handleWholeFormChangeForAutocomplete={() => {}}
        forceValidation={false}
        touched={touched.bookedByPhone}
        errors={errors.bookedByPhone}
        theme={theme}
        required
        label="Mobile"
        uiStyle={FlagPhonePickerStyle.widget}
      />
      <TextField
        className={classNames({
          [style.formText]: true,
          [style.formTextUseEnlargedFont]: useEnlargedFont
        })}
        id="bookedByEmail" name="bookedByEmail" label="Email *"
        fullWidth
        {...getFieldProps('bookedByEmail')}
        error={touched.bookedByEmail && Boolean(errors.bookedByEmail)}
        helperText={touched.bookedByEmail ? errors.bookedByEmail : ''}
        inputProps={{autoCapitalize:"off"}}
      />
      <TextField
        className={classNames({
          [style.formText]: true,
          [style.formTextUseEnlargedFont]: useEnlargedFont
        })}
        id="bookedByCompany" name="bookedByCompany" label="Company Name"
        fullWidth
        {...getFieldProps('bookedByCompany')}
        error={Boolean(errors.bookedByCompany)}
        helperText={touched.bookedByCompany ? errors.bookedByCompany : ''}
      />
  </div>
  ), [errors, bookedByMode, values, touched])

  const NoteSection = useMemo(() => (
    <Paper elevation={1} className={classNames({
      [style.paperBox]: true,
      [style.paperBox2]: true
    })}>

      <TextField
        className={classNames({
          [style.terms]: true,
          [style.formText]: true,
          [style.formTextUseEnlargedFont]: useEnlargedFont
        })}
        id="notes" name="notes" label="Notes" rows={hasTags ? 3 : 5}
        fullWidth multiline
        {...getFieldProps('notes')}
        // eslint-disable-next-line react/prop-types
        error={touched.notes && Boolean(errors.notes)}
        helperText={touched.notes ? errors.notes : ''}
      />

      {renderIf(hasTags, () => (
        <div>
          <div className={style.specialWrap}>
            <Typography variant="subtitle1" >
              Special Requirements
            </Typography>

            <div className={classNames({
              [style.tagsWrap]: true,
              [style.tagsWrapIsLandscape]: isLandscape
            })}>
              {tags.map(({ _id, name }) => (
                <TagLabel key={_id}
                          data-testid="tag-label"
                  className={classNames({
                    [style.tagControl]: true,
                    [style.tagControlIsStacked]: !isLandscape,
                    [style.tagControlIsStandard]: isStandard
                  })}
                  control={
                    <Checkbox
                      id={_id} name={_id}
                      className={style.checkbox}
                      {...getFieldProps(_id)}
                      icon={<CheckBoxOutlineBlankIcon fontSize="default" />}
                      checkedIcon={<CheckBoxIcon fontSize="default" color="secondary" />}
                    />
                  }
                  label={name}
                />
              ))}
            </div>
          </div>
          <Divider className={[style.divider, style.specialDivider2].join(' ')} />
        </div>
      ))}

      {/*-----temp comment out those code for BB checkbox, to see the feedbacks from business---*/}
      {/*{renderIf((bookedByMode), () => (*/}
      {/*  <FormControl className={style.spaceForTopMiddle} required component="fieldset">*/}
      {/*    <FormControlLabel*/}
      {/*      defaultChecked={defaultEmailSubscribed}*/}
      {/*      className={style.subScriptionControl}*/}
      {/*      control={*/}
      {/*        <Checkbox*/}
      {/*          id="bookedSubscribed" name="bookedSubscribed" required*/}
      {/*          {...getFieldProps('bookedSubscribed')}*/}
      {/*          className={style.checkbox}*/}
      {/*          icon={<CheckBoxOutlineBlankIcon fontSize="default" />}*/}
      {/*          checkedIcon={<CheckBoxIcon fontSize="default" color="secondary" />}*/}
      {/*        />*/}
      {/*      }*/}
      {/*      label={*/}
      {/*        MessageService.getMessage(*/}
      {/*          titleSubscription || "",*/}
      {/*          { venueName },*/}
      {/*          null*/}
      {/*        ) + '(for booked by customer)'}*/}
      {/*    />*/}
      {/*  </FormControl>*/}
      {/*  ))}*/}

      {renderIf(!isEmpty(titleSubscription), () => (
        <FormControl required component="fieldset" className={classNames({
          [style.spaceForTopMiddle]: bookedByMode,
        })}>
         <FormControlLabel
           defaultChecked={defaultEmailSubscribed}
           className={style.subScriptionControl}
           control={
             <Checkbox
               id="subscribed" name="subscribed" required
               {...getFieldProps('subscribed')}
               className={style.checkbox}
               icon={<CheckBoxOutlineBlankIcon fontSize="default" />}
               checkedIcon={<CheckBoxIcon fontSize="default" color="secondary" />}
             />
           }
           label={
             MessageService.getMessage(
               titleSubscription || "",
               { venueName },
               null
             )}
         />
       </FormControl>
      ))}

      <FormControl required component="fieldset">
        <div className={style.termsCheckboxWrapper}>
          <FormControlLabel
            className={style.termsControl}
            control={
              <Checkbox
                id="termsAgreementChecked" name="termsAgreementChecked" required
                {...getFieldProps('termsAgreementChecked')}
                className={style.checkbox}
                icon={<CheckBoxOutlineBlankIcon fontSize="default" />}
                checkedIcon={<CheckBoxIcon fontSize="default" color="secondary" />}
              />
            }
            label=""
          />
          {renderLabelTermAndCondition()}
        </div>
        {renderIf(!values.termsAgreementChecked && forceValidation, () => (
          <div className={style.alert}>
            <AlertPanel wrapperStyle={wrapperStyle} message="You need to agree to the terms and conditions before proceeding" />
          </div>
        ))}
      </FormControl>
    </Paper>
  ), [values, errors, hasTags, touched])

  const withRequiredIfNoBookedBy = (label: string) => {
    return !isBookedBy ? `${label} *` : label
  }

  return (
    <form noValidate className={style.root} data-testid="container" onSubmit={handleSubmit}>
      <ColumnWrap2Container>
        {/* left */}
        <Box>
          <Paper data-testid="left-sub-container" elevation={1} className={classNames({
            [style.paperBox]: true,
            [style.paperBox1]: true,
            [style.paperBox1IsLandscape]: isLandscape
          })}>
            <Box className={style.customerTitleWithBbCheckBox}>
              <Box display="flex" gridGap={8} alignItems="center">
                <Typography variant="subtitle1" >
                  Customer Details
                </Typography>
                <Tooltip placement='top-start' title={'Please enter details of the person that will be seated at the venue for this booking'}>
                  <InfoIcon fontSize='small'/>
                </Tooltip>
              </Box>
              {renderIf(enableBookedByOnWidget && !standbyData, () => (
                <FormControlLabel
                  control={
                    <Checkbox
                      id="bookedBy" name="bookedBy" required
                      {...getFieldProps('isBookedBy')}
                      className={style.checkbox}
                      icon={<CheckBoxOutlineBlankIcon fontSize="default" />}
                      checkedIcon={<CheckBoxIcon fontSize="default" color="primary" />}
                    />
                  }
                  label="I am booking on behalf of someone else"
                  classes={{label: style.bookedBySelectorLabel}}
                  className={style.bookedBySelector}
                />
              ))}

            </Box>
            <TextField
              className={classNames({
                [style.formText]: true,
                [style.formTextUseEnlargedFont]: useEnlargedFont
              })}
              id="firstName" name="firstName" label="First Name *"
              fullWidth
              {...getFieldProps('firstName')}
              error={touched.firstName && Boolean(errors.firstName)}
              helperText={touched.firstName ? errors.firstName : ''}
            />
            <TextField
              className={classNames({
                [style.formText]: true,
                [style.formTextUseEnlargedFont]: useEnlargedFont
              })}
              id="lastName" name="lastName" label="Last Name *"
              fullWidth
              {...getFieldProps('lastName')}
              error={touched.lastName && Boolean(errors.lastName)}
              helperText={touched.lastName ? errors.lastName : ''}
            />
            <FlagPhonePickerReact
              searchCustomer={() => {
                return;
              }}
              required={!isBookedBy}
              label="Mobile"
              customers={[]}
              value={values.phone}
              handleBlur={handleBlur}
              handleChange={handleChange}
              country={country}
              handleWholeFormChangeForAutocomplete={() => {}}
              forceValidation={false}
              touched={touched.phone}
              errors={errors.phone}
              theme={theme}
              uiStyle={FlagPhonePickerStyle.widget}
          />
            <TextField
              className={classNames({
                [style.formText]: true,
                [style.formTextUseEnlargedFont]: useEnlargedFont
              })}
              id="email" name="email" label={withRequiredIfNoBookedBy("Email")}
              fullWidth
              {...getFieldProps('email')}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email ? errors.email : ''}
              inputProps={{autoCapitalize:"off"}}
            />
            <TextField
              className={classNames({
                [style.formText]: true,
                [style.formTextUseEnlargedFont]: useEnlargedFont
              })}
              id="company" name="company" label="Company Name"
              fullWidth
              {...getFieldProps('company')}
              error={touched.company && Boolean(errors.company)}
              helperText={touched.company ? errors.company : ''}
            />
            { bookedByMode ? BookedByCustomer : null }
          </Paper>
        </Box>


        {/* right */}
        <div className={classNames({
          [style.column2]: true
        })} data-testid="right-sub-container">
          {NoteSection}
          {
            !!standbyData && !!isDetectedPreviousSB &&
            <StandbyConfirmPanelContainer />
          }
          {
            !(!!standbyData && isDetectedPreviousSB) &&
            <FooterNavContainer />
          }
        </div>
      </ColumnWrap2Container>
    </form>
  );
}


/**
 * Formik wrapper
 */
export const CustomerDetailsForm = withFormik({
  // validateOnMount: true,
  mapPropsToValues: (mapProps: IStateFromProps & IDispatchFromProps) => {
    const {
      customerDetails, tags, country, phoneWithoutPrefix,
      defaultEmailSubscribed,
      isCustomerInformationUpdated,
      bookedBy,
      isBookedBy,
      standbyData
    } = mapProps;
    if (!customerDetails) {
      return {
        firstName: '',
        lastName: '',
        email: '',
        notes: '',
        phone: '',
        company: '',
        country: '',
        subscribed: false,
        termsAgreementChecked: false,
        tags: [],
      };
    }

    const {firstName, lastName, email, company, notes, subscribed, _id, termsAgreementChecked} = customerDetails
    const {firstName: bookedByFirstName = '', lastName: bookedByLastName = '', email: bookedByEmail = '', company: bookedByCompany = '', phone: bookedByPhone = ''} = (bookedBy || {})

    // Get country code from customerDetail's phone when it exists.
    let customerCountry: string;
    if(customerDetails.phone) {
        const parseNumber: any = PhoneNumberService.parseNumber(customerDetails.phone);
        if(parseNumber && typeof parseNumber !== 'string') {
          if(parseNumber.nationalNumber)
          customerCountry = parseNumber.country;
        }
    }

    // const customerCountry = customerDetails.country;

    // sets initial values in the form (must use empty strings, not null)
    const props = {
      firstName: firstName || '',
      lastName: lastName || '',
      email: email || '',
      notes: notes || '',
      // phone: PhoneNumberService.formatInterNationalPhoneNumber(phone, customerCountry || country) || '',
      phone: phoneWithoutPrefix || '',
      company: company || '',
      // country: customerCountry || country || '',
      country: customerCountry || country || '',
      subscribed: (isCustomerInformationUpdated || !isEmpty(_id)) ? subscribed : !!defaultEmailSubscribed,
      bookedSubscribed: (isCustomerInformationUpdated || !isEmpty(_id)) ? subscribed : !!defaultEmailSubscribed,
      termsAgreementChecked: (isCustomerInformationUpdated || !isEmpty(_id)) ? termsAgreementChecked : false,
      bookedByFirstName, bookedByLastName, bookedByEmail, bookedByCompany, bookedByPhone,
      isBookedBy: standbyData ? null : isBookedBy,
      ...tags.reduce((a: any, t) => {
        a[t._id] = t.selected || false;
        return a;
      }, {}),
    };
    return props;
  },

  // Custom validation rules
  validate: (values: IFormData & IFormTags, props: IStateFromProps & IDispatchFromProps) => {
    const errors: Record<string, string> = {};
    // console.log('run validate')
    const country = props.country;
    if (values.phone && !PhoneNumberService.formatNumber(values.phone, country, true, phoneNumberTypes.INTERNATIONAL)) {
      errors.phone = 'This is not a valid phone number';
    }

    if (values.isBookedBy) {
      if (!values.bookedByFirstName?.length) {
        errors.bookedByFirstName = 'First name is required'
      }
      if (!values.bookedByLastName?.length) {
        errors.bookedByLastName = 'Last name is required'
      }
      if (values.bookedByPhone && !PhoneNumberService.formatNumber(values.bookedByPhone, country, true, phoneNumberTypes.INTERNATIONAL)) {
        errors.bookedByPhone = 'This is not a valid phone number';
      }

      if (!values.bookedByPhone?.length) {
        errors.bookedByPhone = 'Mobile is required'
      }
      if (!values.bookedByEmail?.length) {
        errors.bookedByEmail = 'Email is required'
      }
      if (!values.firstName?.length) {
        errors.firstName = 'First name is required'
      }
      if (!values.lastName?.length) {
        errors.lastName = 'Last name is required'
      }
    } else {
      if (!values.firstName?.length) {
        errors.firstName = 'First name is required'
      }
      if (!values.lastName?.length) {
        errors.lastName = 'Last name is required'
      }
      if (!values.email?.length) {
        errors.email = 'Email is required'
      }
      if (!values.phone?.length) {
        errors.phone = 'Mobile is required'
      }
    }
    return errors;
  },
  validationSchema: Yup.object({
    email: Yup.string()
      .email(`Sorry, that email address isn't valid`),
    bookedByEmail: Yup.string()
      .when('isBookedBy', {
        is: true, // alternatively: (val) => val == true
        then: Yup.string().email(`Sorry, that email address isn't valid`),
      })
  }),
  // not using this, but it is mandatory property
  handleSubmit: (values, { setSubmitting, setTouched, setFieldTouched }) => {
  }
})(InnerForm);

