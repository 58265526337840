import {IScheduleService, IScheduleTime, ISectionOrder} from "shared-types/WidgetTypes";
import {cloneDeep, sortBy} from "lodash";
import {ISelectableTime} from "app/components/TimePicker/types";
import {TimeFilterService} from "app/services/timeFilter/timeFilter.service";
import {IVenue} from "app/models";
import {IBooking, ICoversOrServiceChange} from "app/services/booking/booking.types";

const NS = 'SectionsAvailableService';

export class SectionsAvailableService {

  // NB: getSectionsOnCoversOrServiceChange used to be part of BookingService
  static getSectionsOnCoversOrServiceChange(
    activeService: IScheduleService,
    activeVenue: IVenue,
    booking: IBooking,
    prevActiveSection: ISectionOrder,
    manuallyChosenSectionId: string
  ): ICoversOrServiceChange {

    const maxPeoplePerBookingDefault = activeVenue.widgetSettings.maxPeoplePerBooking;
    let filteredSections = this.getSections(activeService, booking.covers, maxPeoplePerBookingDefault);
    filteredSections = this.getFilteredSections(activeService, filteredSections);

    let activeSection;
    if (!activeVenue.widgetSettings.canCustomersChooseSection) {
      activeSection = null;
    } else {
      activeSection = manuallyChosenSectionId && prevActiveSection && filteredSections.find(s => s.id === prevActiveSection.id) || null;
    }

    // if only 1 section exists we don't show it, just select it by default
    if (filteredSections.length === 1) {
      activeSection = filteredSections[0];
    }

    booking.sectionId = activeSection ? activeSection.id : null;

    return {
      booking,
      activeSection,
      filteredSections
    }
  }

  // NB: getFilteredSections used to be part of BookingService
  static getFilteredSections(service: IScheduleService, sections: ISectionOrder[]): ISectionOrder[] {
    const clonedTimes = service ? cloneDeep(service.times) : [];
    const filteredSections: ISectionOrder[] = sections.filter(sec => {
      const availableTimes: ISelectableTime[] = TimeFilterService.getFilteredTimesAndMutateOriginal(clonedTimes, true, sec);
      return !!availableTimes.length;
    });

    return filteredSections;
  }

  /**
   * Filters out sections when covers are out of allowed ranges for the service.
   * @param service
   * @param covers
   * @param maxPeoplePerBookingDefault - comes from `widget.activeVenue.widgetSettings.maxPeoplePerBooking`
   */
  // NB: getSections used to be part of BookingService
  static getSections(service: IScheduleService, covers: number, maxPeoplePerBookingDefault: number): ISectionOrder[] {

    if (!service || !service.sections || !service.sections.length) {
      return [];
    }

    /**
     * Within 'schedule' -> 'payments & booking options' there are fields for:
     * - 'peopleRequired': which is the minimum covers required to show the booking options
     * - 'maxPeoplePerBookingOverride': which will override the default maximum covers (set in account/admin app)
     */
    const maxOverride = service.paymentDetails.maxPeoplePerBookingOverride;
    const peopleRequiredForPayment = service.paymentDetails.peopleRequired;
    const useOverride = maxOverride && covers >= peopleRequiredForPayment;

    /**
     * This check shouldn't be necessary because the CoversInput component restricts input, but
     * we're leaving it in there just in case input logic breaks (maybe in some untested/old browser).
     */
    if (
      /**
       * If service has no paymentDetails with special min/max cover rules
       */
      (!useOverride && covers <= maxPeoplePerBookingDefault) ||
      /**
       * If service does have special rules for min/max covers
       */
      (covers <= maxOverride && covers >= peopleRequiredForPayment)
    ) {
      return sortBy(service.sections, 'order');
    }

    return [];
  }

  static getIsSelectedServiceAvailable(activeService: IScheduleService, isStandbyMode: boolean, filteredSections: ISectionOrder[]): boolean {
    if (!activeService) {
      return false;
    }
    const clonedTimes = activeService ? cloneDeep(activeService.times) : []; // must clone times first to avoid mutating the times in DateUtilsService.getFilteredTimesAndMutateOriginal
    let flag = isStandbyMode;
    !isStandbyMode && filteredSections.some((section: ISectionOrder, index: number) => {
      const availableTableTime: ISelectableTime[] = TimeFilterService.getFilteredTimesAndMutateOriginal(clonedTimes, true, section)
        .filter(t => !t.isDisabled && !t.isBlocked);

      if(availableTableTime.length > 0){
        flag = true;
        return true;
      }
      return false;
    });
    return flag;
  }

  static getAvailableSectionsMap(times: IScheduleTime[], sections: ISectionOrder[]): Map<string, boolean> {
    const clonedTimes = cloneDeep(times); // must clone times first to avoid mutating the times in DateUtilsService.getFilteredTimesAndMutateOriginal
    const _availableSections = new Map<string, boolean>();
    sections.forEach((section: ISectionOrder) => {
      // DateUtilsService.getFilteredTimesAndMutateOriginal(activeService.times, true, section, utcTime)
      const availableTableTime: ISelectableTime[] = TimeFilterService.getFilteredTimesAndMutateOriginal(clonedTimes, true, section)
        .filter(t => !t.isDisabled && !t.isBlocked);

      _availableSections.set(section.id, availableTableTime.length !== 0);
    });
    return _availableSections;
  }

  static getBlockOutSectionsSet(times: IScheduleTime[], sections: ISectionOrder[]): Set<string> {
    const clonedTimes = cloneDeep(times); // must clone times first to avoid mutating the times in DateUtilsService.getFilteredTimesAndMutateOriginal
    const blockoutSections = new Set<string>();
    sections.forEach((section: ISectionOrder) => {
      // DateUtilsService.getFilteredTimesAndMutateOriginal(activeService.times, true, section, utcTime)
      const isWholeSectionBlocked = TimeFilterService.getFilteredTimesAndMutateOriginal(clonedTimes, true, section)
        .every(t => t.isBlockOut || t.isBlocked);

        if (isWholeSectionBlocked) {
          blockoutSections.add(section.id);
        }
    });
    return blockoutSections;
  }

  static getAvailableSections(times: IScheduleTime[], sections: ISectionOrder[]): ISectionOrder[] {
    const availableSections = this.getAvailableSectionsMap(times, sections);
    return sections.filter(s => availableSections.get(s.id));
  }
}
